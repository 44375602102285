// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import { HeaderToastProvider } from "../../blocks/ecompro-admin-components/src/Snackbar/HeaderToastContext"
import Header from "../../blocks/ecompro-components/src/Header/Header.web";
import Footer from "../../blocks/ecompro-components/src/Footer/Footer.web";
import WhatsappWidget from "../../blocks/ecompro-components/src/WhatsappWidget/WhatsappWidget.web";
import LoaderWebBlock from '../../blocks/ecompro-admin-components/src/LoaderWebBlock/LoaderWebBlock.web';
import DialogBlock from '../../blocks/ecompro-admin-components/src/DialogBlock/DialogBlock';

import '../../blocks/ecompro-router/src/App.css';
import HeaderToastBlock from '../../blocks/ecompro-admin-components/src/HeaderToastBlock/HeaderToastBlock.web'
import { ThemeProvider } from '@material-ui/core'
import theme from '../../blocks/ecompro-router/src/theme'
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre.web";
import '../../blocks/ecompro-router/src/assets/fontastic/styles.css';

const routeMap = {
  Home: {
    component: require('../../blocks/ecompro-router/src/WebRoutes').default,
    path: '*',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  DialogWeb: {
    component: DialogBlock,
    path: '*/dialog',
    modal: true
  },
  HeaderToastWeb: {
    component: HeaderToastBlock,
    path: '*/toast',
    modal: true
  },
  LoaderWeb: {
    component: LoaderWebBlock,
    path: '*/loader',
    modal: true
  },
  HelpCentre: {
    component: HelpCentre,
    path: "/help-centre/:slug?",
    exact: true
  },
};

const routeMapWithProductDescription = { ...routeMap };
routeMapWithProductDescription.ProductDescription = {
  component: ProductDescription,
  path: "/:categorySlug/:subCategorySlugOrId/:idOrVariantId?/:variantId?",
  exact: true,
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const isAdminRoute = window.location.href.includes('admin');
    const { storeName } = JSON.parse(localStorage.getItem("customerServices") || '{ "storeName": "Store" }');
    document.title = isAdminRoute ? 'Admin Portal' : storeName;

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <ThemeProvider theme={theme}>
          <HeaderToastProvider>
            {isAdminRoute ?
              <>{WebRoutesGenerator({ routeMap })}</>
            :
              <>
                <Header />
                {WebRoutesGenerator({ routeMap: routeMapWithProductDescription })}
                <WhatsappWidget />
                <Footer />
              </>
            }
          </HeaderToastProvider>
        </ThemeProvider>
        <ModalContainer />
      </View>
    );
  }
}

export default App;
