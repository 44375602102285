import React from "react"
// Customizable Area Start

export interface ImageGalleryNavIconProps {
    direction: "left" | "right";
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
}

class ImageGalleryNavIcon extends React.Component <ImageGalleryNavIconProps,{}> {
    render(){
        return(
            <>
                {this.props.direction === "left" &&
                <button className="image-gallery-icon image-gallery-left-nav" onClick={this.props.onClick} disabled={this.props.disabled}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                         <path d="M22.6659 4.47633C22.0126 3.82299 20.9592 3.82299 20.3059 4.47633L9.22594 15.5563C8.70594 16.0763 8.70594 16.9163 9.22594 17.4363L20.3059 28.5163C20.9592 29.1696 22.0126 29.1696 22.6659 28.5163C23.3192 27.863 23.3192 26.8096 22.6659 26.1563L13.0126 16.4896L22.6792 6.823C23.3192 6.183 23.3192 5.11633 22.6659 4.47633Z" fill="#0F172A" />
                     </svg>
                 </button>
                }
                 <button className="image-gallery-icon image-gallery-right-nav" onClick={this.props.onClick} disabled={this.props.disabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M9.32762 28.5163C9.98095 29.1696 11.0343 29.1696 11.6876 28.5163L22.7676 17.4363C23.2876 16.9163 23.2876 16.0763 22.7676 15.5563L11.6876 4.47633C11.0343 3.82299 9.98095 3.82299 9.32762 4.47633C8.67428 5.12966 8.67428 6.183 9.32762 6.83633L18.9809 16.503L9.31428 26.1696C8.67428 26.8096 8.67428 27.8763 9.32762 28.5163Z" fill="#0F172A" />
                    </svg>
                </button>
            
            </>
        )
    }
}

export default ImageGalleryNavIcon;
// Customizable Area End
