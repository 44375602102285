import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
import { debounce } from "lodash";
import { IMenuItem } from "./Types";
import { RouteComponentProps } from "react-router";
import { eventEmitter } from "../../../../framework/src/utils/EventEmitter";
import { setStorageData } from "framework/src/Utilities";
const { queryParameters } = configJSON;

export type Props = RouteComponentProps & {
  classes: any;
};

interface S {
  countValue: number;
  isLoading: boolean;
  isSearchLoading: boolean;
  anchorEl: any;
  isMobile: boolean;
  isMenuOpen: boolean;
  searchValue: string;
  menuItems: IMenuItem[];
  selectedMenuItem: any | null;
  moreClicked: boolean;
  isSearchOpen: boolean;
  isSearchPage: boolean;
  searchItems: any[];
  historySearchItems: any[];
  moreButtonAnchor: null | Element;
  gtmScript: string;
  storeName: string;
}

interface SS {
  id: any;
}
// Customizable Area End

export default class AppHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetMenuItemsCallId: string = "";
  apiGetCategoriesCallId: string = "";
  apiGetSuggestedSearchCallId: string = "";
  getCartItemCountCallId: string = "";
  getStoreDetailsCallId: string = "";
  getCustomerServiceApiEndpointCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      countValue: 0,
      isLoading: true,
      isSearchLoading: false,
      anchorEl: null,
      isMobile: window.innerWidth < 768,
      isMenuOpen: false,
      searchValue: '',
      menuItems: [],
      selectedMenuItem: null,
      moreClicked: false,
      isSearchOpen: false,
      isSearchPage: false,
      searchItems: [],
      historySearchItems: JSON.parse(localStorage.getItem('searchHistory') || '[]'),
      moreButtonAnchor: null,
      gtmScript: '',
      storeName: '',
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleGetMenuItemsResponse(apiRequestCallId, responseJson);
      this.handleGetCategoriesResponse(apiRequestCallId, responseJson);
      this.handleGetSuggestedSearchResponse(apiRequestCallId, responseJson);
      this.handleGetCartItemCountResponse(apiRequestCallId, responseJson);
      this.handleGetStoreDetailsResponse(apiRequestCallId, responseJson);
      this.handleCustomerServiceApiEndpointResponse(apiRequestCallId, responseJson);
    }
  }

  handleCustomerServiceApiEndpointResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCustomerServiceApiEndpointCallId) {
      if (responseJson) {
        const attr = responseJson.data.attributes;
        const { address_line_1, address_line_2, country_name, zipcode } = attr;
        const storeName = attr?.name;
        this.setState({ storeName });
        const customerPhone = attr?.phone_number;
        const customerEmail = attr?.customer_support_email;
        let customerAddress = address_line_1 ? `${address_line_1}` : '';
        customerAddress += address_line_2 ? `, ${address_line_2}` : '';
        customerAddress += country_name ? `, ${country_name}` : '';
        customerAddress += zipcode ? `, ${zipcode}` : '';
        const emailAndBuildCard = { email: attr.email, build_card: attr.build_card }
        setStorageData("eventData", JSON.stringify(emailAndBuildCard))

        if (
          localStorage.getItem('customerServices') === JSON.stringify({ storeName, customerPhone, customerEmail, customerAddress })
          || localStorage.getItem('currency_type') === attr.currency_symbol
        ) {
          localStorage.setItem('customerServices', JSON.stringify({ storeName, customerPhone, customerEmail, customerAddress }));
          localStorage.setItem('currency_type', attr.currency_symbol);
        } else {
          localStorage.setItem('customerServices', JSON.stringify({ storeName, customerPhone, customerEmail, customerAddress }));
          localStorage.setItem('currency_type', attr.currency_symbol);
          window.location.reload();
        }
      }
    }
  }

  handleGetMenuItemsResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetMenuItemsCallId) {
      if (responseJson) {
        this.setState({ isLoading: false });
        const menuItems = responseJson.map((item: any, itemIndex: number) => {
          const title = item.data.attributes.items.title;
          const url = item.data.attributes.items.url;

          if (!item.data.attributes.menu_items) {
            return {
              id: `item-${itemIndex}`, title, url
            };
          }

          const menuItems = item.data.attributes.menu_items.map((subItem: any, subItemIndex: number) => {
            const subItemTitle = subItem.attributes.title;
            const subItemUrl = subItem.attributes.url;
            return { id: `subItem-${subItemIndex}`, title: subItemTitle, url: subItemUrl };
          });

          return { id: `item-${itemIndex}`, title, url, menuItems };
        });

        this.setState({ menuItems })
      }
    }
  }

  handleGetCategoriesResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetCategoriesCallId) {
      if (responseJson) {
        const menuItems = responseJson.data.map((item: any) => {
          const categoryId = item.id;
          const title = item.attributes.name;
          const url = `/catalogue?${queryParameters.Category + '=' + item.id}`;

          if (item.attributes.sub_categories.length === 0) {
            return { id: categoryId, categoryName: title, itemUrl: url };
          }

          const menuItems = item.attributes.sub_categories.map((subItem: any) => {
            const subItemId = subItem.id;
            const subItemTitle = subItem.name;
            const subItemUrl = `/catalogue?${queryParameters.Category + '=' + item.id}&${queryParameters.SubCategory + '=' + subItem.id}`;
            return { id: subItemId, itemName: subItemTitle, itemUrl: subItemUrl };
          });

          return { id: categoryId, categoryName: title, itemUrl: '', categoryItems: menuItems, webItemUrl: url };
        });

        this.setState({ menuItems: [...this.state.menuItems, ...menuItems], isLoading: false }, this.initializeCategories);
      }
    }
  }

  handleGetSuggestedSearchResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetSuggestedSearchCallId) {
      this.setState({ isSearchLoading: false, searchItems: [] });

      if (responseJson) {
        if (responseJson.catalogues.data.length > 0) {
          const catalogues = responseJson.catalogues.data.map((item: any) => {
            const productSlug = item.attributes.slug;
            const categorySlug = item.attributes.category.slug;
            const subCategorySlug = item.attributes.selected_sub_category?.slug;
            let slug = `${categorySlug}/${productSlug}`;
            if (subCategorySlug) {
              slug = `${categorySlug}/${subCategorySlug}/${productSlug}`;
            }
            const productUrl = item.attributes.variant_id ? `/${slug}/${item.attributes.variant_id}` : `/${slug}`;
            return { id: item.id, name: item.attributes.name, url: productUrl, type: 'catalogue', image: item.attributes.images ? item.attributes.images[0].url : '' };
          });
          this.setState({ searchItems: [...this.state.searchItems, ...catalogues] });
        }

        if (responseJson.categories.data.length > 0) {
          const categories = responseJson.categories.data.map((item: any) => {
            return { id: item.id, name: item.attributes.name, url: `/catalogue?${queryParameters.Category + '=' + item.id}`, type: 'category', image: item.attributes.image ? item.attributes.image.url : '' };
          });
          this.setState({ searchItems: [...this.state.searchItems, ...categories] });
        }
        if (responseJson.sub_categories.data.length > 0) {
          const subCategories = responseJson.sub_categories.data.map((item: any) => {
            const categoryId = this.state.menuItems.find((menuItem: any) => menuItem.categoryItems.find((subItem: any) => Number(subItem.id) === Number(item.id)))?.id;
            return { id: item.id, name: item.attributes.name, url: `/catalogue?${queryParameters.Category + '=' + categoryId}&${queryParameters.SubCategory + '=' + item.id}`, type: 'sub_category' };
          });
          this.setState({ searchItems: [...this.state.searchItems, ...subCategories] });
        }

        if (responseJson.catalogues.data.length === 0 && responseJson.categories.data.length === 0 && responseJson.sub_categories.data.length === 0) {
          this.setState({ searchItems: [] });
        }
      }
    }
  }

  handleGetCartItemCountResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCartItemCountCallId) {
      this.setState({ isLoading: false });
      this.handleCartItemCountRespense(responseJson)
    }
  }

  handleGetStoreDetailsResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getStoreDetailsCallId) {
      const isProperJson = (str: string) => {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      }

      if (responseJson) {
        const gtmScript = responseJson.data.attributes.gtm_script ?? ""
        this.setState({ isLoading: false, gtmScript });
        let themesAndFont = responseJson.data.attributes.themes_and_font.data.attributes;
        let header = responseJson.data.attributes.header.data.attributes;
        let footer = responseJson.data.attributes.footer.data.attributes;
        let sections = responseJson.data.attributes.sections.data.map((section: any) => {
          return section.attributes;
        });
        let metadata =
          isProperJson(responseJson.data.attributes.metadata)
            ? JSON.parse(responseJson.data.attributes.metadata) : {};

        themesAndFont = {
          primaryColor: themesAndFont.primary_colour,
          secondaryColor: themesAndFont.secondary_colour,
          accentColor: `${themesAndFont.primary_colour}1A`,
          headerTextFontFamily: themesAndFont.header_text,
          bodyTextFontFamily: themesAndFont.body_text,
        }

        const setToCssRoot = function (vars: { [key: string]: string }) {
          for (const key in vars) {
            document.documentElement.style.setProperty(key, vars[key]);
          }
        }

        setToCssRoot({
          '--design-primary-color': themesAndFont.primaryColor,
          '--design-primary-opacity-color': `${themesAndFont.primaryColor}99`,
          '--design-secondary-color': themesAndFont.secondaryColor,
          '--design-accent-color': themesAndFont.accentColor,
          '--design-header-text-font': themesAndFont.headerTextFontFamily,
          '--design-body-text-font': themesAndFont.bodyTextFontFamily,
        });

        const getMenuItems = () => {
          const allCategories = metadata.headerNavigationData.menuData[0].options;
          let countOfItems = 0;
          return metadata.headerNavigationData.menuData.map((menu: any) => {
            if (countOfItems > 3) {
              return;
            }
            if (menu.selectedOption !== "Select") {
              countOfItems += 1;
              return {
                categoryName: menu.selectedOption,
                itemUrl: `/catalogue?${queryParameters.Category + '=' + allCategories.find((category: any) => category.label === menu.selectedOption)?.value}`
              }
            }
          });
        }

        header = {
          storeLogo: header.logo_url,
          storeTextLogoDetails: { logoColor: header.logo_colour, logoFont: header.logo_font },
          customFavicon: header.custom_favicon,
          favIcon: header.favicon_url,
          headerItems: getMenuItems(),
        }

        footer = {
          showContactDetails: footer.is_contact,
          showSocialLinks: footer.is_social_media,
          facebookUrl: footer.facebook_url,
          instagramUrl: footer.instagram_url,
          twitterUrl: footer.x_url,
          linkedInUrl: footer.linkedin_url,
          youtubeUrl: footer.youtube_url,
          showAppLinks: footer.is_app_link,
          appStoreUrl: footer.appstore_url,
          playStoreUrl: footer.playstore_url,
          copyRightText: footer.copyright,
        }

        sections = sections.map((section: any, index: number) => {
          if (section.section_type === 'category') {
            const categories = JSON.parse(section.metadata).categories.map((category: any) => {
              if (category.isSelected) {
                return {
                  name: category.category_name,
                  url: `/catalogue?${queryParameters.Category + '=' + category.id}`,
                  image: category.category_image
                }
              }
            });

            return {
              sectionType: 'category',
              sectionTitle: section.section_name,
              categories
            }
          } else if (section.section_type === 'product') {
            if (!isProperJson(section.metadata)) {
              return {
                sectionType: 'product',
                sectionTitle: section.section_name,
                products: []
              }
            }
            const products = JSON.parse(section.metadata).products.map((product: any) => {
              if (product?.productAttrs) {
                return {
                  id: product.productAttrs.id,
                  slug: product.productAttrs.slug,
                  name: product.productAttrs.product_name,
                  url: `/${product.productAttrs.slug}`,
                  imageUrl: product.productAttrs.product_image,
                  price: product.productAttrs.old_rate.toString(),
                  discountedPrice: product.productAttrs.new_rate,
                  discount: product.productAttrs.discount
                }
              }
            });

            return {
              sectionType: 'product',
              sectionTitle: section.section_name,
              products
            }
          } else if (section.section_type === 'banner') {
            const getTextDetails = (metadata: any) => {
              return {
                fontColor: metadata.font_colour,
                fontSize: metadata.font_size,
                textContent: metadata.header_text,
                textPosition: metadata.text_position,
                textAlignment: metadata.text_alignment,
              }
            }

            const mobileBannerMetadata = JSON.parse(isProperJson(section.mobile_banner_metadata)
              ? section.mobile_banner_metadata : '{}');
            const desktopBannerMetadata = JSON.parse(isProperJson(section.desktop_banner_metadata)
              ? section.desktop_banner_metadata : '{}');

            const banner = {
              mobileBannerUrl: section.mobile_banner_url || '',
              mobileBannerTextDetails: getTextDetails(mobileBannerMetadata),
              desktopBannerUrl: section.desktop_banner_url || '',
              desktopBannerTextDetails: getTextDetails(desktopBannerMetadata),
              directionUrl: section.banner_url,
            }

            return {
              firstHeaderBanner: index === 0,
              sectionType: 'banner',
              banner
            }
          }
        });

        this.setFavicon(header, this.state.storeName);

        if (localStorage.getItem('designDetails') === JSON.stringify({ themesAndFont, header, footer, sections })) {
          localStorage.setItem('designDetails', JSON.stringify({ themesAndFont, header, footer, sections }));
        } else {
          localStorage.setItem('designDetails', JSON.stringify({ themesAndFont, header, footer, sections }));
          window.location.reload();
        }
      }
    }
  }

  setFavicon = (headerData: { storeTextLogoDetails: { logoColor: string; logoFont: string; }; customFavicon: boolean; favIcon: string; }, storeName: string = 'S') => {
    const text = storeName;

    const textColor = headerData.storeTextLogoDetails.logoColor;
    const faviconElement = document.querySelector('[rel="shortcut icon"]') as HTMLAnchorElement;

    let imgElement = document.createElement('img');
    imgElement.src = faviconElement.href;

    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    canvas.width = 128;
    canvas.height = 128;

    imgElement.onload = () => { this.applyFavicon(canvas, context, imgElement, faviconElement, text, { logo_font: headerData.storeTextLogoDetails.logoFont, textColor, custom_favicon: headerData.customFavicon, favicon_url: headerData.favIcon }) }
  }

  applyFavicon = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D | null, imgElement: HTMLImageElement, faviconElement: HTMLAnchorElement, text: string, headerData: { logo_font: string; textColor: string; custom_favicon: boolean; favicon_url: string; }) => {
    const bgColor = '#ffffff';
    if (context) {
      // Draw Original Favicon as Background
      context.drawImage(imgElement, 0, 0, 128, 128);

      // Draw Notification Circle
      context.beginPath();
      context.rect(0, 0, canvas.width, canvas.height);

      context.fillStyle = bgColor;
      context.fill();

      // Draw Notification Number
      context.font = `bold 110px "${headerData.logo_font}", sans-serif`;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = headerData.textColor;

      let letter = text.substring(0, 1);
      context.fillText(letter, canvas.width / 2, 76);
      // Replace favicon
      faviconElement.href = headerData.custom_favicon ? headerData.favicon_url : canvas.toDataURL('image/png');
    }
  };

  handleMoreButtonOpen = (event: React.MouseEvent) => {
    this.setState({ moreButtonAnchor: event.currentTarget })
  }

  handleMoreButtonClose = () => {
    this.setState({ moreButtonAnchor: null })
  }

  handleCartItemCountRespense = (responseJson: any) => {
    if (responseJson?.data) {
      let count = responseJson.data.attributes.cart_items_count
      this.setState({ countValue: count })
    }
  }

  handleMoreClicked = () => {
    this.setState({ moreClicked: true });
  }

  handleClick = (event: any, menuItem: any) => {
    this.setState({ anchorEl: event.currentTarget, selectedMenuItem: menuItem });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, moreClicked: false });
  };

  handleMenuOpen = (isSearchPage: boolean) => {
    this.setState({ isMenuOpen: true, selectedMenuItem: null, isSearchPage });
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  handleSearchVisibility = (action: 'blur' | 'focus') => {
    if (action === 'blur') {
      setTimeout(() => this.setState({ isSearchOpen: false }), 200)
    } else {
      this.setState({ isSearchOpen: true })
    }
  }

  handleSearchKeyDown = (key: string) => {
    const { searchValue, historySearchItems } = this.state;

    if (key === 'Enter' && searchValue) {
      if (!historySearchItems.includes(searchValue)) {
        localStorage.setItem('searchHistory', JSON.stringify([searchValue, ...historySearchItems]))
      }
      this.handleRedirect(`/catalogue?search=${searchValue}`)
    }
  }

  debouncedSearch = debounce(() => this.state.searchValue
    ? this.getSuggestedSearch()
    : this.setState({ isSearchLoading: false, searchItems: [] })
    , 500);

  setSearchValue = (value: string) => {
    this.setState({ isSearchLoading: true, searchValue: value }, this.debouncedSearch);
  };

  handleSearchChange = (event: any) => {
    this.setSearchValue(event.target.value);
  };

  handleRedirect = (url: string) => {
    const filteringFor: 'category' | 'sub_category' | undefined =
      url.includes(queryParameters.SubCategory) ? 'sub_category' :
        url.includes(queryParameters.Category) ? 'category' : undefined;
    let filterId = url.split('=')[1];
    if (filteringFor === 'category') {
      this.props.history.push(url, { category: this.state.menuItems.find((item: any) => item.id === filterId)?.categoryName });
      localStorage.setItem('category', this.state.menuItems.find((item: any) => item.id === filterId)?.categoryName || '');
      localStorage.removeItem('subCategory');
    } else if (filteringFor === 'sub_category') {
      filterId = url.split('=')[2];
      const subCategory = this.state.menuItems.find((item: any) => item.categoryItems?.find((subItem: any) => Number(subItem.id) === Number(filterId)))?.categoryItems!.find((subItem: any) => Number(subItem.id) === Number(filterId))?.itemName;
      const category = this.state.menuItems.find((item: any) => item.categoryItems?.find((subItem: any) => Number(subItem.id) === Number(filterId)))?.categoryName;
      this.props.history.push(url, { category, subCategory });
      localStorage.setItem('category', category || '');
      localStorage.setItem('subCategory', subCategory || '');
    } else {
      this.props.history.push(url);
    }
    window.location.reload();
  };


  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false, isMenuOpen: false });
    }
  };

  async componentDidMount() {
    eventEmitter.subscribe("badgeValue", (event: any) => {
      this.setState({ countValue: event })
    });
    const id = await localStorage.getItem("cartId")
    if (id) {
      this.getCartItemCount(id)
    }
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.getCategories();
    this.getStoreDetails();
    this.getCustomerServiceApiEndpoint();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.isMobile !== this.state.isMobile) {
      if (this.state.isMobile) {
        this.setState({ menuItems: [] }, this.getCategories);
      } else {
        this.setState({ menuItems: [] }, () => {
          this.getMenuItems();
          this.getCategories();
        });
      }
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getMenuItems = () => {

    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMenuItemsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategories = () => {

    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSuggestedSearch = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSuggestedSearchCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suggestedSearchEndpoint + this.state.searchValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  initializeCategories = () => {
    const url = new URL(window.location.href);
    const currentCategoryName =
      this.state.menuItems.find((item: any) => url.searchParams.get(queryParameters.Category) === item.id)?.categoryName || '';
    const currentSubCategoryName =
      this.state.menuItems.find((item: any) => url.searchParams.get(queryParameters.Category) === item.id)?.categoryItems?.find((subItem: any) => Number(url.searchParams.get(queryParameters.SubCategory)) === Number(subItem.id))?.itemName || '';
    localStorage.setItem('category', currentCategoryName || '');
    localStorage.setItem('subCategory', currentSubCategoryName || '');
  }

  getCartItemCount = (cartId: any) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemCountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cartItemCountEndpoint}/${cartId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStoreDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStoreDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerServiceApiEndpoint = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerServiceApiEndpointCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomerServiceApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
