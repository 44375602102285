// @ts-nocheck
import * as React from "react";
  // Customizable Area Start
import { useEffect, useState } from "react";
import Nav from "../Nav";
import FadeInDropdown from "../Dropdown/FadeInDropdown/FadeInDropdown";
import { RoundIconBadge } from "../Badge/RoundIconBadge/RoundIconBadge";
import { Scrollbars } from "react-custom-scrollbars";
import CircularProgressBar from "../Charts/CircularProgressBar";
import "./assets/css/style.css";
const Logo: any = require("./assets/images/temp/logo.png");
import { getStorageData } from "../../../../framework/src/Utilities";
  // Customizable Area End

interface LayoutProps {
  children: any;
}

export const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  // Customizable Area Start
  const [hideNav, setHideNav] = useState(false);
  const [adminUser, setAdminUser] = useState({});
  const [progress, setProgress] = useState(0);
  const [storeName, setStoreName] = useState("Store Name");

  const getCachedValues = async () => {
    setStoreName(await getStorageData("store_name") || "Store Name")
    setAdminUser(JSON.parse((await getStorageData("adminuser") || "{}")));
    const progress = await getStorageData("progress");
    setProgress(progress ? JSON.parse(progress) : 0);
  };

  useEffect(() => {
    getCachedValues();
    async function checkStorageData() {
      const admin = JSON.parse(await getStorageData("adminuser"));
      const progressData = await getStorageData("progress");

      if (admin) {
        setAdminUser(admin);
      }
      if (progressData) {
        setProgress(progressData);
      }
    }
    window.addEventListener("progressInserted", checkStorageData);

    return () => {
      window.removeEventListener("progressInserted", checkStorageData);
    };
  }, []);

  const { children, hideLayout } = props;
  if (hideLayout) {
    return <div className="admin-layout__content">{children}</div>;
  }
  let menuList = [
    {
      label: "Managing Orders",
      iconEl: <RoundIconBadge iconName="orders" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475072-managing-orders",
    },
    {
      label: "Setting and Managing inventory",
      iconEl: <RoundIconBadge iconName="list" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475081-setting-and-managing-inventory",
    },
    {
      label: "Setting up store and emails",
      iconEl: <RoundIconBadge iconName="wallet" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475108-setting-up-store-and-emails",
    },
    {
      label: "Configuring business settings",
      iconEl: <RoundIconBadge iconName="settings" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475115-configuring-business-settings",
    },
    {
      label: "Payments and Shipping",
      iconEl: <RoundIconBadge iconName="products" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475121-payments-and-shipping",
    },
  ];
  let profileList = [
    {
      label: "Account Overview",
      iconName: "user",
      internalUrl: "/admin/AdminAccount",
    },
    {
      label: "Logout",
      showRightArrow: false,
      iconName: "logout",
    },
  ];
  return (
    <div className={`admin-layout ${hideNav ? "admin-layout--hide-nav" : ""}`}>
      <div className="admin-layout__sidebar">
        <div className="admin-layout__brand-logo">
          <img className="img-fluid" src={Logo} alt="logo" />
        </div>
        <Scrollbars>
          <div className="admin-layout__link-container">
            <Nav />
          </div>
        </Scrollbars>
      </div>
      <div className="admin-layout__main">
        <div className="admin-layout__nav d-flex align-items-center justify-content-between">
          <div className="f-14 d-flex align-items-center fw-normal text-capitalize">
            <a
              href="#"
              className="admin-layout__hamburger"
              onClick={(e) => {
                e.preventDefault();
                setHideNav(!hideNav);
              }}
            >
              <i className="icon-menu f-18"></i>
            </a>
            &nbsp; &nbsp;
            {storeName}
          </div>
          <div className="d-flex align-items-center">
            <FadeInDropdown
              className="me-4"
              menuClassName="setup-guide-menu"
              menuLists={menuList}
              label="Setup guide"
              badgeEl={<CircularProgressBar percentage={progress} />}
              menuLabel={"Setup guide"}
            />
            <FadeInDropdown
              menuLists={profileList}
              label={`Hi, ${adminUser?.data?.attributes?.name || "Admin User"}`}
              menuHeader={
                <div className="">
                  <h6 className="f-14 mb-0 text-blue-grey-10">{`${adminUser
                    ?.data?.attributes?.name || "Admin User"}`}</h6>
                  <p className="f-10 mb-0 text-blue-grey-8">{`${adminUser?.data
                    ?.attributes?.email || "Admin@User.com"}`}</p>
                </div>
              }
              badgeName="sj"
              menuLabel={"Setup guide"}
            />
          </div>
        </div>
        <div className="admin-layout__content">{children}</div>
      </div>
    </div>
  );
  // Customizable Area End
};
