import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Paper,
  Tooltip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider ,withStyles} from "@material-ui/core/styles";
import { Helmet } from 'react-helmet';
import withLoader from "../../ss-cms-common-components/src/HOC/withBrandingSpinner.web";
import withHeaderActionBar from "../../ss-cms-common-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../ss-cms-common-components/src/Dialog/withDialog";
import withLayout from "../../ss-cms-common-components/src/withLayout/withLayout"
import withSnackBar from '../../ss-cms-common-components/src/HOC/withSnackBar.Web';

import { aiStarImage, tooltipImageSvg, closeIcon, plusIcon } from './assets';

const ModifiedTooltip =  withStyles(({
  tooltip: {
    backgroundColor: 'transparent',
  },
}))(Tooltip);
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SearchengineoptimisationseoController, {
  Props,
  configJSON,
} from "./SearchengineoptimisationseoController";

export default class Searchengineoptimisationseo extends SearchengineoptimisationseoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAiChip = () => {
    return (
      <AiButton
        disabled={this.getAiButtonDisableStatus()}
        onClick={this.generateAIKeywords}
        endIcon={<img src={aiStarImage} alt={configJSON.starAlt} />}
      >
       {configJSON.generateWithAIText}
      </AiButton>
    )
  }

  renderChipBox = () => {
    return (
      <Box className={this.props.classes.editConfigureConOne}>
        <Box className='title_heading_ai_container'>
          <Box className="title_heading_container">
            <Typography data-testid='title_heading' className="title_heading">{configJSON.keywordsText}</Typography>
            <ModifiedTooltip placement="bottom-start" title={
              <Paper style={webStyle.tooltip}>
                <Typography className="tooltip_title">  {configJSON.editTitleTooltipText} </Typography>
              </Paper>
            }>
              <img src={tooltipImageSvg} alt={configJSON.tooltipAltText} />
            </ModifiedTooltip>
          </Box>
          <Box>
            {this.renderAiChip()}
          </Box>
        </Box>
        <Box className={this.props.classes.tagContainer}>
          <Box className={this.props.classes.tagItemStyle} >
            {this.state.seoData.keywords.map((chip, keyIndex) => (
              <Box data-test-id={'aiKeywords'} className={this.props.classes.chipTextStyle} key={keyIndex}>
                <Typography className='chip_title'>{chip}</Typography>
                <IconButton data-test-id='deleteKey' onClick={() => this.handleChipDelete(keyIndex)}>
                  <img src={closeIcon} alt={configJSON.cloasAlt} />
                </IconButton>
              </Box>
            ))}
           {!this.state.isAddKeywordActive &&  <Button 
            onClick={this.handleAddNewChip}
            data-test-id='addNewButton'
            className={this.props.classes.addButton}
            startIcon={<img src={plusIcon} alt={configJSON.plusAlt} />}
            >
              {configJSON.addNewButtonText}
              </Button>}
            {this.state.isAddKeywordActive && <Input
              disableUnderline
              value={this.state.newKeyWordData}
              inputRef={this.addNewInputRef}
              onChange={this.handleAddChipText}
              onKeyDown={this.handleKeyDown}
              data-test-id="tagInputTestId"
              className={this.props.classes.addNewInput}
              />}
          </Box>
        </Box>
        <Typography data-test-id='infoText' className={`caution ${this.state.isAddKeywordActive && "opacity"}`}>{configJSON.pressEnterInfoText}</Typography>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyle.bodyContainer}>
          {
            <Box sx={webStyle.mainContainer}>
              <Box>
                <Box sx={webStyle.seoTitle}>{configJSON.seoTitle}</Box>
                <Box sx={webStyle.subTitle}>{configJSON.seoSubTitle}</Box>
              </Box>
              <Box className={this.props.classes.inputContainer}>
              <Typography className="title">{configJSON.storeLabel}</Typography>
                <input
                  placeholder={configJSON.storePleaceholder}
                  className={this.props.classes.input}
                  value={this.state.seoData.storeName}
                  onChange={this.handleInputChange}
                  name={"storeName"}
                  data-test-id='storeName'
                />
                <Typography data-test-id='errorMessage' className={`error_styles ${this.getStoreNameErrorData() && "opacity"}`}>{configJSON.storeNameErrorMessage}</Typography>
              </Box> 
              <Box className={this.props.classes.inputContainer}>
                <Typography className="title">{configJSON.aboutLabel}</Typography>
                <textarea
                  className={`${this.props.classes.input} ${this.props.classes.textInput}`}
                  placeholder={configJSON.aboutPlaceHolder}
                  value={this.state.seoData.description}
                  onChange={this.handleInputChange}
                  name={'description'}
                  data-test-id='description'
                />
                <Typography className={`error_styles ${this.getDescriptionErrorData() && "opacity"}`}>{configJSON.businessDetailsErrorMessage}</Typography>
              </Box>
              {!this.state.isGeneralSettingsCompletedOnce && <ButtonType
                onClick={this.handleContinueButtonClick}
                data-test-id="formSubmitID">
                {configJSON.buttonLabel}
              </ButtonType>}
            {this.state.isGeneralSettingsCompletedOnce && this.renderChipBox()}
            </Box>}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as "column",
    alignItems: "start",
    paddingBottom: "30px",
    background: "#fff",
  },
  mainWrapper2: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  mainWrapper3: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as "column",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
    marginTop: "10px",
  },
  mainWrapper4: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row" as "row",
  },
  mainWrapper5: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row" as "row",
    flexWrap: "nowwrap" as "nowrap",
    paddingBottom: "30px",
    background: "#fff",
    width: "100%",
    justifyContent: "space-between",
    alignItems: 'center',
    marginTop: "10px",
    paddingLeft: "10px",
  },
  mainWrapper6: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row" as 'row',
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "center",
    width: "100%",
  },
  buttonStyle: {
    padding: '10px 20px',
    marginTop: "30px",
    border: "none",
    boxShadow: "none",
  },
  buttonStyle1: {
    padding: '10px 20px',
    marginTop: "30px",
    border: "none",
    boxShadow: "none",
    width: "100%",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
  buttonStyle2: {
    padding: '10px 20px',
    border: "none",
    boxShadow: "none",
    width: "48%",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
  },
  buttonStyle3: {
    border: "none",
    boxShadow: "none",
  },
  buttonStyle4: {
    padding: '10px 20px',
    border: "none",
    boxShadow: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    textTransform: "none" as 'none',
  },
  textFieldStyle: {
    marginTop: '10px',
  },
  mainContainer: {
    width: "70%",
    borderRadius: "3px",
    border: "2px solid #ECEDF0",
    margin: "30px",
    padding: "24px 30px",
    display: "flex",
    flexDirection: "column" as 'column',
    gap: "24px",
    background: "white"
  },
  seoTitle: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    textAlign: "left" as 'left',
    color: "#3C3E49",
    marginTop: "6px"
  },
  subTitle: {
    marginTop: "8px"
  },
  aiTitleStyle: {
    fontSize : "28px", 
    fontWeight: 500, 
    color: "#3C3E49"
  },
  contentStyle: { 
    fontSize: "16px", 
    fontWeight: 400, 
    color: "#000000" 
  },
  businessInputLabel: {
    color: "black",
    fontSize: "12px",
    fontWeight: 500,
    marginBottom: "8px"
  },
  buisnessInputContainer: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    overflow: "auto",
    borderRadius: "4px",
    padding: '11px',
    alignItems: 'center',
    gap: '10px',
    width: "100%",
    position: 'relative' as 'relative',
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    flexWrap: "wrap" as 'wrap',
  },
  chipStyle: {
    backgroundColor: "#F7F5FF",
    color: "#4F31E4",
    border: "1px solid #BCB1F5",
    borderRadius: "16px",
    margin: "2px",
    fontSize: "13px"
  },
  chipInputStyle: {
    position: "absolute" as 'absolute',
    right: "10px"
  },
  bodyContainer: {
    background: "#f8f9fa",
    height: "100%"
  },
  progressbarOuter: { 
    display: "flex", 
    justifyContent: "center", 
    alignContent: "center" 
  },
  progressbarInner: { 
    display: "flex", 
    gap: "20px", 
    width: "45%",
    justifyContent: "center"
  },
  input: {
    minHeight: "48px",
    width: "100%",
    border: "1px solid #DFE0E6",
    outline: "2px solid transparent",
    padding: "1rem",
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    paddingRight: "2.9rem",
    transition: "all 0.5s",
    borderRadius: "3px",
    color: "#000000",
    "&:hover": {
      outline: "2px solid #6200ea",
    },
    "&::placeholder": {
      color: "#AFB2C0"
    },

  },
  textInput: {
    minHeight: "93px",
    padding: "1rem",
    resize: "vertical" as "vertical",
    paddingRight: "1rem",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "4px",
    "& .error_styles":{
      fontFamily: 'Rubik',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      color:"red",
      opacity:0,
      marginBottom:"-1rem"
  },
  "& .opacity":{
      opacity: 1,
  },
    "& .title":{
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    color: "#000000"
    }
  },
  tagCrossButtonStyle: {
    position: "absolute" as "absolute",
    right: "10px"
  },
  chipTextStyle: {
    display: "flex",
    backgroundColor: "#F8F9FA",
    alignItems: "center",
    borderRadius: "2rem",
    padding:"0 2px 0 13px",
    margin: "2px",
    "& .chip_title": {
      fontSize: "14px",
      fontFamily: "Rubik",
      color: "#000000",
    }
  },
  tagContainer: {
    display: 'flex',
    border: '1px solid #D0D2DA',
    overflow: "auto",
    borderRadius: "4px",
    padding: '11px',
    alignItems: 'center',
    gap: '10px',
    width: "100%",
    position: 'relative' as "relative",
  },
  tagItemStyle: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    flexWrap: "wrap" as "wrap"
  },
  editConfigureConOne: {
    width: "100%",
    "& .caution":{
      fontFamily: 'Rubik',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      color:"#676B7E",
      opacity:0,
    },
    "& .opacity":{
      opacity: 1,
    },
    "& .title_input_container": {
        display: "flex",
        gap: ".5rem",
        flexDirection: "column",
        marginTop: "1.5rem"
    },
    "& .edit_description": {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: "inherit",
    },
    "& .title_heading_container": {
        display: "flex",
        gap: ".5rem",
        "& img": {
            width: "18px",
            aspectRatio: "1/1",
            cursor:"pointer",
        }
    },
    "& .title_heading": {
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        textAlign: 'left',
        color: "#000000"
    },
    "& .title_heading_ai_container": {
        display: "flex",
        gap: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom:".5rem",
    },
},
  tooltip: {
    padding: ".65rem",
    "& .tooltip_title": {
        color: "#334155",
        fontFamily: 'Rubik',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '13px',
        textAlign: 'left',
    },
},
  addButton: {
    backgroundColor: "#F0E5FF",
    color: "#6200EA",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: '14px',
    padding: "3px 11px",
    borderRadius: "2rem",
    textTransform: "none" as "none",
    "&:hover": {
      backgroundColor: "#F0E5FF",
    }
  },
  addNewInput:{
    fontFamily:"Rubik",
    fontSize:"14px",
    color:"#000000",
    fontWeight:400,
  }
};

const AiButton = styled(Button)({
  backgroundColor: "#00D659",
  borderRadius: "2rem",
  padding: "3px 11px",
  cursor:"pointer",
  textTransform: "none" as "none",
  fontFamily: 'Rubik',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  color: "#0F172A",
  "&:hover":{
    backgroundColor: "#00D659",
 },
 "&.Mui-disabled":{
   backgroundColor: "#E2E8F0",
   color:"#64748B"
 }
})

const ButtonType = styled(Button) ({
    textTransform: "none" as "none",
    border: "3px",
    padding: "12px 40px",
    fontSize: "16px",
    boxShadow: "none",
    fontFamily: "Rubik",
    lineHeight: 1.5,
    backgroundColor: "#00D659",
    width: 204,
    height: 48,
    "&:hover" : {
      background: "#00D659"
    }
  });


const SEOWithWrapper = withStyles(webStyle) (withLayout(withDialog(withHeaderActionBar(withLoader(withSnackBar(Searchengineoptimisationseo))))));
export { SEOWithWrapper }
// Customizable Area End
